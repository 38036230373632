import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class GlobalFontControlService {
  globalFontSize = 62.5;

  constructor() {}

  setZoomValue() {
    document.documentElement.style.fontSize = this.globalFontSize + "%";
  }

  resetZoom() {
    this.globalFontSize = 62.5;
    this.setZoomValue();
  }

  handleZoom(mode: string) {
    if (mode == "SMALLER") {
      this.globalFontSize -= 10;
      if (this.globalFontSize < 10) {
        this.globalFontSize = 10;
      }
    }
    if (mode == "BIGGER") {
      this.globalFontSize += 10;
      if (this.globalFontSize > 100) {
        this.globalFontSize = 100;
      }
    }
    if (mode == "DEFAULT") {
      this.resetZoom();
    }
    this.setZoomValue();
  }
}
