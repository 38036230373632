@if (dialogsService.loadingCount.value > 0) {
  <div class="progress-bar">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </div>

  <div id="dark-div">
    @if (dialogsService.loadingMessage.value) {
      <h1>{{ dialogsService.loadingMessage.value }}</h1>
    } @else {
      <h1>Please wait, loading...</h1>
    }
  </div>
}
