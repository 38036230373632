// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export enum BASE_URLS {
  UAT = "",
  LOCAL = "",
  PROD = "",

  SECURITY = "/fmw-security-ui",
}

export enum API_BASE_CONTEXT_ROOTS {
  OAUTH2 = "" + "/oauth2",
  SECURITY = "" + "/fmw-security-ms",
  HOME = "" + "/fmw-home-ms",
  USER_ADMIN = "" + "/fmw-user-admin-ms",
}

export enum DEPLOYMENT_MODES {
  UAT,
  PROD,
}

export enum VERSION_DATA {
  appVersionMajor = 0,
  appVersionMinor = 0,
  appVersionRevision = 39,
}

export const environment = {
  appName: "TNPFTS",
  configServiceId: "ifms.config",
  production: false,
  debug: true,
  baseUrl: BASE_URLS.PROD,
  baseIp: BASE_URLS.PROD,
  baseIpLocal: BASE_URLS.LOCAL,
  deployMode: DEPLOYMENT_MODES.PROD,
  nativeNetworkCall: false,
  isMobileApp: false,
  appVersion:
    VERSION_DATA.appVersionMajor +
    "." +
    VERSION_DATA.appVersionMinor +
    "." +
    VERSION_DATA.appVersionRevision,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
