import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DialogService {
  public loadingCount: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public loadingMessage: BehaviorSubject<string> = new BehaviorSubject<string>('Please wait, loading...');

  constructor() { }

  public showLoader(message: string = 'Please wait, loading...') {
    console.log('show loader');
    // this.isLoading.next(true);
    this.loadingCount.next(this.loadingCount.value + 1);
    this.loadingMessage.next(message);
  }

  public hideLoader() {
    console.log('hide loader');
    // this.isLoading.next(false);
    this.loadingCount.next(this.loadingCount.value - 1);
  }
}
