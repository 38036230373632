import { Component, OnInit } from "@angular/core";
import { ConfigService } from "../core/services/config.service";
import { Buffer } from "buffer";
import { TranslateService } from "@ngx-translate/core";
import { GlobalFontControlService } from "../core/services/global-font-control.service";
import { GlobalThemeControlService } from "../core/services/global-theme-control.service";

@Component({
  selector: "updated-header",
  templateUrl: "./updated-header.component.html",
  styleUrls: ["./updated-header.component.scss"],
})
export class UpdatedHeaderComponent implements OnInit {
  links: any = [];

  constructor(
    private _configService: ConfigService,
    public _fontControl: GlobalFontControlService,
    public _themeControl: GlobalThemeControlService,
    public _translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.getHeaderLinks();
  }

  langChange(lang: any): void {
    this._translate.use(lang);
    this._configService.lang.next(lang);
  }

  getHeaderLinks() {
    this._configService.config.subscribe({
      next: (config) => {
        if (JSON.stringify(config) != "[]") {
          this.links = config?.headerLinks;
        }
      },
    });
  }

  openNavLink(link: string) {
    window.open(link, "_blank");
  }
}
