// import { G2DialogsService } from './g2-dialog.service';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';
// import { G2AuthService } from './g2-auth.service';
// import { G2ConfigService } from 'src/app/g2-config/config.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { CryptographyService } from './cryptography.service';
import { DialogService } from './dialog.service';
// import { G2CryptographyService } from './g2-cryptography.service';


const urlSegmentsForIgnoringLoader: string[] = [
  'captcha'
];

@Injectable({
  providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor {

  constructor(
    public dialogsService: DialogService,
    // public authService: G2AuthService,
    // public configService: G2ConfigService,
    public _cryptographyService: CryptographyService,
    public router: Router
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // alert();
    let isLoaderShown: boolean = true;

    if (req.method === 'GET'
      // && !req.url.includes('ui/g')
    ) {
      //loader ignored for all GET requests except the request to fetch UI 
      isLoaderShown = false;
    }

    for (let segment of urlSegmentsForIgnoringLoader) {
      //factoring in specific exclusions for loader.
      if (req.url.includes(segment)) {
        isLoaderShown = false;
      }
    }

    if (isLoaderShown) {
      this.dialogsService.showLoader();
    }
    return next.handle(req).pipe(
      catchError((err) => {
        // // alert(JSON.stringify(err));
        // if (err.status == 403 || err.statusText.includes("Forbidden")) {
        //   if (this.configService.isLoggedIn.value === true) {
        //     this.dialogsService.infoConfirm('Error', 'Access to this resource is forbidden.', 'You may go back to dashboard, or logout and try again', 'Logout', 'F', 'Back to Dashboard', false, true).subscribe((data) => {
        //       if (data && typeof (data) === 'boolean' && data === true) {
        //         console.log("🚀 ~ file: g2-interceptor.service.ts ~ line 66 ~ G2InterceptorService ~ this.dialogsService.infoConfirm ~ logout chosen after forbidden error");
        //         this.authService.logout(true);
        //       } else {
        //         console.log("🚀 ~ file: g2-interceptor.service.ts ~ line 68 ~ G2InterceptorService ~ this.dialogsService.infoConfirm ~ back to dashboard after forbidden error");
        //         this.router.navigateByUrl('/g2-dashboard');
        //       }
        //     }, err => {
        //       console.log("🚀 ~ file: g2-interceptor.service.ts ~ line 72 ~ G2InterceptorService ~ this.dialogsService.infoConfirm ~ error in dialog, still going back to dashboard");
        //       this.router.navigateByUrl('/g2-dashboard');
        //     });
        //   } else {
        //     console.log("🚀 ~ file: g2-interceptor.service.ts ~ line 72 ~ G2InterceptorService ~ this.dialogsService.infoConfirm ~ forbidden, not logged in, going back to home");
        //     this.router.navigateByUrl('');
        //   }

        //   // if (this.configService.isLoggedIn.value === true) {
        //   //   this.router.navigateByUrl('/g2-dashboard')
        //   // } else {
        //   //   this.authService.logout();
        //   // }
        // } else if (err.status == 404) {
        //   console.log(err);
        //   // this.router.navigateByUrl('/');
        // }
        return throwError(err);
      }),
      map(event => {
        if (event instanceof HttpResponse) {
          if (event.status != 200) {// alert(JSON.stringify(event));
            // alert(event.status);
            // if (event.status === 203) {
            //   this.dialogsService.showToast('Session expired or invalid. Please login to try again.');
            //   clearInterval(this.authService.authInterval);
            //   this.authService.logout(true, false);
            // }
          } else {
            // if (this.configService.configLoaded.value === true && this.configService.commonConfig && this.configService.commonConfig.config
            //   && Array.isArray(this.configService.commonConfig.config) && this.configService.commonConfig.config[0]
            //   && this.configService.commonConfig.config[0]['nr_flag'] && this.configService.commonConfig.config[0]['nr_flag'] === 'Y'
            //   && localStorage.getItem('unique-tab-id')) {
            //   if (event.url.includes(environment.baseUrl) && !event.url.includes(environment.configServiceId)) {
            //     let receivedNrToken = event.headers.get('Api-NR-Token');
            //     let computedTabIdHash = this.cryptographyService.convertStringToSha512String(localStorage.getItem('unique-tab-id'));

            //     if (receivedNrToken != computedTabIdHash) {
            //       console.log("🚀 ~ file: g2-interceptor.service.ts ~ line 77 ~ G2InterceptorService ~ intercept ~ NR token mismatch, possible packet switching\nservice:" + event.url + "\n received NR token: " + receivedNrToken + " \n computed tab ID hash: " + computedTabIdHash + "\n");

            //       this.dialogsService.errorDialog('Something went wrong', 'Please try again later, and contact administrator if problem persists. [ERR:6786]');
            //     }
            //   }
            // }
          }
        } else {
          // alert(JSON.stringify(event))

        }
        return event;
      }),
      finalize(
        () => {
          //this.dialogsService.isLoading.next(false);
          if (isLoaderShown) {
            this.dialogsService.hideLoader();
          }
        }
      )
    )

  }
}
