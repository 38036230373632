import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { ReqrescontrollerService } from "../services/reqrescontroller.service";
import { CryptographyService } from "../services/cryptography.service";
import { DialogService } from "../services/dialog.service";
import Swal from "sweetalert2";

@Component({
  selector: "captcha-box",
  templateUrl: "./captcha-box.component.html",
  styleUrls: ["./captcha-box.component.scss"],
})
export class CaptchaBoxComponent implements OnInit {
  @Output() captchaButtonEvent = new EventEmitter();
  @Output() captchaRefreshEvent = new EventEmitter();
  captchaBase64Text: string = "";
  captchaValue: string = "";
  expiryTime: any;

  constructor(
    private _reqService: ReqrescontrollerService,
    private _cryptographyService: CryptographyService,
    public _dialogsService: DialogService
  ) {}

  ngOnInit(): void {
    console.log("captcha oninit");
    this.loadNewCaptcha();

    // if (Date.now() > parseInt(window.sessionStorage.getItem("captcha_expiry_timestamp"))) {

    // }
  }

  loadNewCaptcha(): void {
    // console.log("loadNewCaptcha");
    this.captchaValue = "";
    this.captchaBase64Text = undefined;
    this.captchaRefreshEvent.emit(this.captchaValue);
    // this._reqService.generateCaptcha().subscribe(
    //   (resp) => {
    //     console.log(resp);
    //     this.captchaBase64Text = resp.data.captcha;
    //     window.sessionStorage.setItem("c_data_1", resp.data.id);
    //     window.sessionStorage.setItem("c_data_2", resp.data.expires_in);
    //     window.sessionStorage.removeItem("c_data_3");
    //     this.expiryTime = resp.data.expires_in;
    //     // console.log(this.captchaBase64Text);
    //   },
    //   (err) => {
    //     // this.dialogsService.showToast(err);
    //   }
    // );

    this._reqService.generateCaptchaV2().subscribe({
      next: (data: any) => {
        console.log(data);
        if (
          data &&
          data.sts &&
          data.sts.status &&
          data.sts.status === "S" &&
          data.respData &&
          data.respData.captchaId &&
          data.respData.captchaBase64
        ) {
          this.captchaBase64Text = data.respData.captchaBase64;
          window.sessionStorage.setItem("c_data_1", data.respData.captchaId);
          window.sessionStorage.removeItem("c_data_3");
        }
      },
      error: (err) => {
        Swal.fire({
          icon: "error",
          text: "Something went wrong!",
        });
      },
    });

    // this._reqService.generateCaptchaV2().subscribe((data) => {
    //   console.log(data);
    //   if (
    //     data &&
    //     data.sts &&
    //     data.sts.status &&
    //     data.sts.status === "S" &&
    //     data.respData &&
    //     data.respData.captchaId &&
    //     data.respData.captchaBase64
    //   ) {
    //     this.captchaBase64Text = data.respData.captchaBase64;
    //     window.sessionStorage.setItem("c_data_1", data.respData.captchaId);
    //     window.sessionStorage.removeItem("c_data_3");
    //   }
    // });
  }

  storeCaptchaAnswerInSession(): void {
    window.sessionStorage.setItem(
      "c_data_3",
      this._cryptographyService.convertStringToSha512String(this.captchaValue)
    );
    this.captchaButtonEvent.emit(this.captchaValue);
    // console.log(window.sessionStorage.getItem("Captcha_Response"));
  }
}
