import { Component } from "@angular/core";
import { GlobalFontControlService } from "./core/services/global-font-control.service";
import { GlobalThemeControlService } from "./core/services/global-theme-control.service";
import { TranslateService } from "@ngx-translate/core";
import { ConfigService } from "./core/services/config.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  title = "global_login_module";
  constructor(
    private _fontControl: GlobalFontControlService,
    private _themeControl: GlobalThemeControlService,
    private _translate: TranslateService,
    private _configService: ConfigService
  ) {}

  ngOnInit() {
    this.globalResets();
    this._configService.getConfig();
    this._translate.use("en");
  }

  globalResets() {
    this._fontControl.resetZoom();
  }
}
